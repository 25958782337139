import React, { useState } from 'react';

const LoveCalculator = () => {
  const [names, setNames] = useState({ name1: '', name2: '' });
  const [compatibility, setCompatibility] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNames(prevNames => ({
      ...prevNames,
      [name]: value
    }));
  };

  const calculateCompatibility = () => {
    // Check for specific names and set compatibility to 100%
    if ((names.name1.toLowerCase() === 'muhaddis' && names.name2.toLowerCase() === 'manal') ||
        (names.name2.toLowerCase() === 'muhaddis' && names.name1.toLowerCase() === 'manal')) {
      setCompatibility('Your compatibility score is: 100%');
    } else {
      // For other names, calculate a random compatibility score
      const score = Math.random() * 100;
      setCompatibility(`Your compatibility score is: ${score.toFixed(2)}%`);
    }
  };

  return (
    <div>
      <input
        type="text"
        name="name1"
        placeholder="Your Name"
        value={names.name1}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="name2"
        placeholder="Crush's Name"
        value={names.name2}
        onChange={handleInputChange}
      />
      <button onClick={calculateCompatibility}>Calculate</button>
      {compatibility && <p>{compatibility}</p>}
    </div>
  );
};

export default LoveCalculator;
