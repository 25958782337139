import React from 'react';
import { motion } from 'framer-motion';
import c1Image from '../images/c1.jpg';
import c2Image from '../images/c2.jpeg';
import c3Image from '../images/c3.jpeg';

const GreetingCard = ({ design }) => {
  const cardAnimations = {
    hover: { scale: 1.1 }, // Example of a hover effect
    tap: { scale: 0.9 }, // Example of a tap effect
  };

  let cardContent;
  switch (design) {
    case "Design 1":
      cardContent = (
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ rotate: [0, 10, -10, 0], scale: 1 }}
          transition={{ duration: 0.8 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="greeting-card"
        >
          <motion.img src={c1Image} alt="Love is in the air"
            whileHover={cardAnimations.hover}
            whileTap={cardAnimations.tap}
            transition={{ duration: 0.2 }} />
          <h2>Love is in the air!</h2>
          <p>May your Valentine's Day be filled with love and happiness.</p>
        </motion.div>
      );
      break;
    case "Design 2":
      cardContent = (
        <motion.div
          initial={{ x: '-100vw' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 120 }}
          className="greeting-card"
        >
          <motion.img src={c2Image} alt="Together Forever"
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 300 }} />
          <h2>Together Forever</h2>
          <p>Walking hand in hand into life's wonderful journey.</p>
        </motion.div>
      );
      break;
      case "Design 3":
        cardContent = (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="greeting-card"
          >
            <motion.div
              whileHover={{ scale: 1.05 }} // Applying hover to the wrapper
              transition={{ duration: 0.3 }} // Ensuring a smooth transition
            >
              <motion.img src={c3Image} alt="You Complete Me"
                initial={{ y: -25 }}
                animate={{ y: 0 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 2,
                  ease: "easeInOut"
                }}
              />
            </motion.div>
            <h2>You Complete Me</h2>
            <p>Every moment with you is a treasure.</p>
          </motion.div>
        );
        break;
      
    default:
      cardContent = <div className="greeting-card">Select a design</div>;
  }

  return cardContent;
};

export default GreetingCard;
