import React, { useState, useRef } from 'react';

import './App.css';
import LoveLetterGenerator from './components/LoveLetterGenerator';
import CardSelector from './components/CardSelector';
import LoveCalculator from './components/LoveCalculator';
import BackgroundSlider from './components/BackgroundSlider';
import AnimatedCard from './components/AnimatedCard';


function App() {
  const audioUrl = process.env.PUBLIC_URL + '/s1.mp3';
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(audioUrl));

  const handleAudioPlay = () => {
    // Request permission to play audio
    audioRef.current.play()
      .then(() => {
        setIsPlaying(true); // Audio started playing
      })
      .catch(error => console.error("Error playing the audio", error));
  };


  return (
    <div className="App">
      <BackgroundSlider />
      <header className="App-header">
        <h1>🌹Happy Valentine's Day Mano 🌹</h1>
        {/* Audio player added below the header */}
        {!isPlaying && (
        <button onClick={handleAudioPlay}>Click Here Love😜</button>
      )}
      </header>
      <main>
        <section>
          <h2>Valentine's Love Letter Generator</h2>
          <LoveLetterGenerator />
        </section>
        <section>
          <h2>Choose Your Valentine's Card</h2>
          <CardSelector />
        </section>
        <section>
          <h2>Love Calculator</h2>
          <LoveCalculator />
        </section>
        <section>
          <h2>Open Your Love Letter</h2>
          <AnimatedCard />
        </section>
      </main>
    </div>
  );
}

export default App;
