import React, { useState } from 'react';
import { motion } from 'framer-motion';
import cardImage from '../images/card.jpg';


const AnimatedCard = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const containerVariants = {
      closed: {
        rotateY: 0,
      },
      open: {
        rotateY: 180,
        transition: {
          duration: 1,
          when: "beforeChildren",
          staggerChildren: 0.3,
        },
      },
    };
  
    const messageVariants = {
      closed: {
        opacity: 0,
        scale: 0.8,
      },
      open: {
        opacity: 1,
        scale: 1,
      },
    };
  
    return (
      <div className="card-container" onClick={() => setIsOpen(!isOpen)}>
        <motion.img src={cardImage} alt="Valentine's Card" variants={containerVariants} animate={isOpen ? "open" : "closed"} />
        <motion.div className="card-message" variants={messageVariants} animate={isOpen ? "open" : "closed"}>
          <p>To my dearest Valentine,</p>
          <p>Every moment with you is like a beautiful dream come true. My love for you grows more with each passing day, and I am so grateful to have you in my life. Happy Valentine's Day, my love!</p>
        </motion.div>
      </div>
    );
  };
  

export default AnimatedCard;
