import React, { useState } from 'react';

const LoveLetterGenerator = () => {
  const [names, setNames] = useState({ yourName: '', partnerName: '' });
  const [message, setMessage] = useState('');
  const [generatedLetter, setGeneratedLetter] = useState('');

  const messages = [
    "You mean the world to me. ❤️",
    "Every moment with you is magical. 💖",
    "You're my dream come true. 🌹",
  ];

  const generateLetter = () => {
    const letter = `Dear ${names.partnerName},\n\n${message}\n\nForever yours,\n${names.yourName}`;
    setGeneratedLetter(letter);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Your Name"
        value={names.yourName}
        onChange={(e) => setNames({ ...names, yourName: e.target.value })}
      />
      <input
        type="text"
        placeholder="Partner's Name"
        value={names.partnerName}
        onChange={(e) => setNames({ ...names, partnerName: e.target.value })}
      />
      <select onChange={(e) => setMessage(e.target.value)}>
        <option>Select a message</option>
        {messages.map((msg, index) => (
          <option key={index} value={msg}>
            {msg}
          </option>
        ))}
      </select>
      <button onClick={generateLetter}>Generate Love Letter</button>
      {generatedLetter && <pre>{generatedLetter}</pre>}
    </div>
  );
};

export default LoveLetterGenerator;
