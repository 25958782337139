import React, { useState } from 'react';
import GreetingCard from './GreetingCard'; // Corrected import for same directory level

const CardSelector = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const cards = ["Design 1", "Design 2", "Design 3"]; // Example card designs

  return (
    <div>
      <h3>Select a Card Design:</h3>
      {cards.map((card, index) => (
        <button key={index} onClick={() => setSelectedCard(card)}>
          {card}
        </button>
      ))}
      {selectedCard && <GreetingCard design={selectedCard} />}
    </div>
  );
};

export default CardSelector;
